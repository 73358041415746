import React from "react";
import { useSelector } from 'react-redux';
// tools
import styled from 'styled-components';
import theme from 'theme';
import JsPDF from 'jspdf';
// utils
import Icon from "utils/icon";
//Il8n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
// Components
import Button from "components/Button/Button";
import { displayName } from "../../../utils/displayUserName";
import A2Logo from 'components/A2Logo';

const MySessionReport = ( { session, votes, returnCallback } ) => {

  const { locale } = useSelector((state) => state.languageReducer);

  const Return = styled.div`
    padding: 16px 32px;
    border-bottom: 1px solid ${theme.ui.grey};
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.darkgray};
  `;

  const Actions = styled.div`
    padding: 8px 32px;
    border-bottom: 1px solid ${theme.ui.grey};
  `;

  const MySessionReportCont = styled.div`
    width: 100%;
  `;

  const ReportContCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: ${theme.ui.grey};
  `;

  const ReportCont = styled.div`
    width: 650px;
    padding: 15px;
    border: 1px solid ${theme.darkgray};
    border-radius: 6px;
    background: ${theme.white};
  `;

  const ReportBody = styled.div`
    background: white;
    font-size: 16px;
  `;

  const PdfHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  `;

  const H1 = styled.h1`
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4em;
  `;

  const UnderH1 = styled.div`
    margin-top: -14px;
    text-transform: uppercase;
    text-align: center;
  `;

  const H2 = styled.h2`
    text-transform: uppercase;
    font-size: 1.2em;
  `;

  const Data = styled.span`
    color: ${theme.blue};
  `;

  const DivSpaceBottom = styled.div`
    margin-bottom: 18px;
  `;

  const getDate = (sessionDate, showDate = true, showTime = true) => {
    if(sessionDate) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = locale == "fr" ? new Date(sessionDate).toLocaleDateString('fr-FR', options) : new Date(sessionDate).toLocaleDateString('en-EN', options);
      const time = locale == "fr" ? new Date(sessionDate).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : new Date(sessionDate).toLocaleTimeString('en-EN', { hour: '2-digit', minute: '2-digit' });
      if(showDate && showTime) {
        return <span>{date} - {time}</span>;
      }
      else if(showDate) {
        return <span>{date}</span>
      }
      else {
        return <span>{time}</span>
      }
    }
    return null;
  }

  const generatePDF = () => {
    const docName = `PV-${session?.title}`.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // encode for safe filename
    const doc = new JsPDF();
    let elementHTML = document.querySelector('#report');
    doc.html(elementHTML, {
      callback: function(doc) {
        // Save the PDF
        doc.save(docName);
      },
      margin: [15, 15, 15, 15],
      autoPaging: 'text',
      x: 0,
      y: 0,
      width: 170, //target width in the PDF document
      windowWidth: 650 //window width in CSS pixels
    });
  }

  return (
    <MySessionReportCont>
      <Return onClick={returnCallback}>
        <Icon icon="LeftArrow" size="sm" />
        <FormattedMessage {...messages.return} />
      </Return>
      {
        session?.status === 2 ?
          <React.Fragment>
            <Actions>
              <Button action={generatePDF}>
                <FormattedMessage {...messages.exportPDF} />
              </Button>
            </Actions>
            <ReportContCont>
              <ReportCont>
                <ReportBody id="report">
                  <PdfHeader>
                    <A2Logo />
                    <H1>Procès-verbal de <Data>{session?.title}</Data></H1>
                    <UnderH1>du <Data>{getDate(session?.date)}</Data></UnderH1>
                  </PdfHeader>
                  {session?.endDate ? 
                      <DivSpaceBottom>
                        <div>Le <Data>{getDate(session.endDate, true, false)}</Data></div>
                        <div>à <Data>{getDate(session.endDate, false, true)}</Data></div>
                      </DivSpaceBottom>
                    :
                      <DivSpaceBottom>
                        <div>Le <Data>{getDate(new Date(), true, false)}</Data></div>
                        <div>à <Data>{getDate(new Date(), false, true)}</Data></div>
                      </DivSpaceBottom>
                  }
                  <DivSpaceBottom>
                    <div>La séance est organisée par <Data>{displayName(session?.createdBy)}</Data>
                    {
                      session.createdBy?.organization &&
                      <React.Fragment>, membre de l'organisation <Data>{session.createdBy?.organization}</Data></React.Fragment>
                    }
                    .
                    </div>
                    <div>L'organisateur de la séance constate que les participants à la séance ont le droit de vote sur l'ensemble des délibérations.</div>
                    <div>Il s'est également assuré de la convocations des participants en amont de la séance, ainsi que de leur présence au début de celle-ci.</div>
                    {
                      session?.location2 &&
                        <div>
                          <div>Les votants ont été invités à rejoindre une vidéoconférence avec le lien suivant : </div>
                          <div><Data>{session.location2}</Data></div>
                        </div>
                    }
                  </DivSpaceBottom>
                  {
                    session?.description &&
                      <DivSpaceBottom>
                        <div>Description de la séance : </div>
                        <div><Data>{session.description}</Data></div>
                      </DivSpaceBottom>
                  }
                  <DivSpaceBottom>
                    <div>Les délibérations qui sont à l'ordre du jour de la présente Séance : </div>
                    <ul>
                      {
                        votes && votes.map((vote) => {
                          return (
                            <li key={vote.id} ><Data>{vote.title}</Data></li>
                          )
                        })
                      }
                    </ul>
                  </DivSpaceBottom>
                  <DivSpaceBottom>
                    <div>Les votants reconnaissent que les documents requis étaient disponible numériquement sur l'interface de vote en ligne.</div>
                    <div>Ordre du jour de la séance : </div>
                    <ul>
                      <li>
                        <Data>{session?.filename || "pas d'ordre du jour"}</Data>
                      </li>
                    </ul>
                    <div>Documents associés aux résolutions : </div>
                    <ul>
                    {
                      votes && votes.length && votes.filter((v)=>v.filename).length ?
                        votes.map((vote) => {
                          if(vote.filename) {
                            return (
                              <li key={vote.id} >{vote.title} : {vote.filename}</li>
                            )
                          }
                        })
                      :
                        <li><Data>pas de document</Data></li>
                    }
                    </ul>
                  </DivSpaceBottom>
                  <div>
                    <H1>Résolutions</H1>
                  </div>
                  <div>
                    {
                      votes && votes.map((vote) => {
                        const countTotal = vote.results?.length;
                        const countYes = vote.results?.filter((result)=>result.simpleResult === 1).length;
                        const countNo = vote.results?.filter((result)=>result.simpleResult === 0).length;
                        const countAbs = vote.results?.filter((result)=>result.simpleResult === 2).length;
                        let fullVote = false;
                        let bestCount = -1;
                        let bestcountPerct = 0;
                        if(countYes !== countNo) {
                          if(countYes > countNo) {
                            bestCount = 1;
                            fullVote = countYes === countTotal;
                            bestcountPerct = countYes/countTotal * 100;
                          }
                          else {
                            bestCount = 0;
                            fullVote = countNo === countTotal;
                            bestcountPerct = countNo/countTotal * 100;
                          }
                        }
                        return (
                          <div key={vote.id} >
                            <H2><Data>{vote.title}</Data></H2>
                            <p><Data>{vote.description}</Data></p>
                            {
                              countTotal ?
                                <React.Fragment>
                                  <DivSpaceBottom>
                                    <div>Votes exprimés : <Data>{countTotal}</Data></div>
                                    <div>Pour : <Data>{countYes}</Data></div>
                                    <div>Contre : <Data>{countNo}</Data></div>
                                    <div>Abtention : <Data>{countAbs}</Data></div>
                                  </DivSpaceBottom>
                                  <div>
                                    {
                                      bestCount >= 0 ?
                                        <DivSpaceBottom>
                                          <div>Les participants ont voté <Data>{fullVote ? "à l'unanimité" : "majoritairement"} {bestCount ? "Pour" : "contre"} {countAbs ? `, avec ${countAbs} absentions` : ""}</Data></div>
                                          <div>Soit <Data>{Math.trunc(bestcountPerct)}</Data>% des votes exprimés</div>
                                        </DivSpaceBottom>
                                      :
                                        <DivSpaceBottom>Les participants ont voté à égalité Pour et Contre</DivSpaceBottom>
                                    }
                                  </div>
                                </React.Fragment>
                              :
                                <div>Votes exprimés : Aucun</div>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </ReportBody>
              </ReportCont>
            </ReportContCont>
          </React.Fragment>
          :
          <ReportContCont>
            <ReportCont>
              <ReportBody>
                Le Procès-Verbal n'est pas encore disponible
              </ReportBody>
            </ReportCont>
          </ReportContCont>
      }
    </MySessionReportCont>
  )
}

export default MySessionReport;