import { defineMessages } from "react-intl";
export default defineMessages({
  return: {
    id: "a2vote.return",
    defaultMessage: "Return",
  },
  close: {
    id: "a2vote.close",
    defaultMessage: "Close",
  },
  unknown: {
    id: "a2vote.mysessions.unknown",
    defaultMessage: "Unknown state",
  },
  against: {
    id: "a2vote.against",
    defaultMessage: "Against",
  },
  for: {
    id: "a2vote.for",
    defaultMessage: "In favour",
  },
  abstain: {
    id: "a2vote.abstain",
    defaultMessage: "Abstain",
  },
  order_day: {
    id: "a2vote.order.day",
    defaultMessage: "Order of the day",
  },
  minutes: {
    id: "a2vote.minutes",
    defaultMessage: "Minutes",
  },
  participant: {
    id: "a2vote.participant",
    defaultMessage: "Participants",
  },
  refresh: {
    id: "a2vote.refresh",
    defaultMessage: "Refresh",
  },
  proxy: {
    id: "a2vote.proxy",
    defaultMessage: "Proxy",
  },
  
  order: {
    id: "mysession.order",
    defaultMessage: "Order of the day"
  },
  report: {
    id: "mysession.report",
    defaultMessage: "Report"
  },
  attendees: {
    id: "mysession.attendees",
    defaultMessage: "Attendees"
  },
  waiting: {
    id: "vote.status.waiting",
    defaultMessage: "Waiting"
  },
  open: {
    id: "vote.status.open",
    defaultMessage: "Open"
  },
  closed: {
    id: "vote.status.closed",
    defaultMessage: "Closed"
  },
  default: {
    id: "vote.status.default",
    defaultMessage: "Unknow"
  },
  0: {
    id: "vote.result.0",
    defaultMessage: "No"
  },
  1: {
    id: "vote.result.1",
    defaultMessage: "Yes"
  },
  2: {
    id: "vote.result.2",
    defaultMessage: "Abstention"
  },
  exportPDF: {
    id: "vote.exportPDF",
    defaultMessage: "PDF export"
  }
});
